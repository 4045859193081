<template>
  <div
    class="modal fade"
    id="mdPasswordChange"
    tabindex="-1"
    aria-labelledby="mdPasswordChangeLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-s p-4">
        <div class="text-center cp mb-3">
          <h5>{{ $t("labels.changePassword") }}</h5>
        </div>
        <div class="d-flex justify-content-end d-md-none d-block">
          <button
            type="button"
            class="btn-close "
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form autocomplete="off" class="">
          <Alert :alerts="alert"></Alert>

          <div class="form-floating mb-3">
            <input
              v-model="oldPassword"
              autocomplete="off"
              :class="{ 'is-invalid': !oldPasswordValide }"
              :type="passwordFieldType"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingPassword">{{ $t("labels.old_password") }}</label>
            <div class="form-text text-end showHidePassword">
              <img
                @click="showHidePassword"
                v-if="passwordFieldType == 'password'"
                class="ico-show-hide"
                src="../assets/img/eye_show.svg"
                alt=""
              />
              <img
                @click="showHidePassword"
                v-else
                class="ico-show-hide"
                src="../assets/img/eye_hide.svg"
                alt=""
              />
            </div>
          </div>

          <div class="form-floating mb-3">
            <input
              v-model="password"
              autocomplete="off"
              :class="{ 'is-invalid': !passwordValide }"
              :type="passwordFieldType"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingPassword">{{ $t("labels.new_password") }}</label>
            <div class="invalid-feedback">
              {{ $t("labels.minimum_password_length") }}
            </div>
            <div class="form-text text-end showHidePassword">
              <img
                @click="showHidePassword"
                v-if="passwordFieldType == 'password'"
                class="ico-show-hide"
                src="../assets/img/eye_show.svg"
                alt=""
              />
              <img
                @click="showHidePassword"
                v-else
                class="ico-show-hide"
                src="../assets/img/eye_hide.svg"
                alt=""
              />
            </div>
          </div>

          <div class="form-floating mb-4">
            <input
              v-model="confirmePassword"
              autocomplete="off"
              :class="{ 'is-invalid': !confirmePasswordValide }"
              :type="passwordFieldType"
              class="form-control"
              placeholder="Password"
            />
            <label>{{ $t("labels.confirm_password") }}</label>

            <div class="form-text text-end showHidePassword">
              <img
                @click="showHidePassword"
                v-if="passwordFieldType == 'password'"
                class="ico-show-hide"
                src="../assets/img/eye_show.svg"
                alt=""
              />
              <img
                @click="showHidePassword"
                v-else
                class="ico-show-hide"
                src="../assets/img/eye_hide.svg"
                alt=""
              />
            </div>
          </div>

          <div class="d-grid gap-2 mt-3">
            <button
              :disabled="loading"
              v-on:click="sigIn"
              class="btn btn-primary"
              type="button"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <img class="me-2" src="@/assets/img/save.svg" alt="send">  {{ $t("buttons.save") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api.js";
import Alert from "@/components/alert/Alert";
import { Modal } from "bootstrap";
export default {
  components: {
    Alert,
  },
  mounted() {
    this.modal = new Modal(document.getElementById("mdPasswordChange"));
  },
  name: "PasswordChange",

  data: function() {
    return {
      password: "",
      confirmePassword: "",
      oldPassword: "",

      oldPasswordValide: true,
      passwordValide: true,
      confirmePasswordValide: true,

      passwordFieldType: "password",

      loading: false,
      modal: "",

      alert: {
        msg: "",
        type: "",
      },
    };
  },
  methods: {
    showHidePassword() {
      this.passwordFieldType =
        this.passwordFieldType == "password" ? "text" : "password";
    },

    sigIn: function() {
      this.oldPasswordValide = this.$valid(this.oldPassword, 8);
      if (!this.oldPasswordValide) return;

      this.passwordValide = this.$valid(this.password, 8);
      if (!this.passwordValide) return;

      this.confirmePasswordValide =
        this.password === this.confirmePassword ? true : false;
      if (!this.confirmePasswordValide) return;

      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("old_password", this.oldPassword);
      bodyFormData.append("new_password", this.password);
      bodyFormData.append("new_password_confirmation", this.confirmePassword);

      api({
        method: "post",
        url: "me/update/password",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data; " },
      })
        .then((response) => {
          if (!response.data.error) {
            this.alert.msg = response.data.messages;
            this.alert.type = "success";
            this.loading = false;
            setTimeout(() => this.modal.hide(), 2000);

            this.password = this.confirmePassword = this.oldPassword = "";
          } else {
            if (response.data.messages) this.alert.msg = response.data.messages;
            else this.alert.msg = "Internal Server Error";
            this.alert.type = "danger";
          }
          this.loading = false;
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 402px;
}
.btn-close {
  position: absolute;
  top: 15px;
  left: 90%;
}
</style>
