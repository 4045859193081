import Vuex from "vuex";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

if (!cookies.get("historyColor"))
  cookies.set("historyColor", [
    "#ff4500",
    "#ff8c00",
    "#ffd700",
    "#90ee90",
    "#00ced1",
    "#1e90ff",
    "#c71585",
    "#ff4500",
    "#ff7800",
    "#00babd",
    "#1f93ff",
    "#fa64c3",
  ]);
const colors = cookies.get("historyColor").split(",");

const langues = ["es", "pt_BR", "pt_PT"];
let lang = null;
if (!cookies.get("lang")) {
  lang = langues.includes(navigator.language.replace("-", "_"))
    ? navigator.language.replace("-", "_")
    : null;
  if (lang == null)
    lang = langues.includes(navigator.language.split("-")[0])
      ? navigator.language.split("-")[0]
      : "en";
} else {
  lang = cookies.get("lang");
}

document.documentElement.lang = lang;

export default new Vuex.Store({
  state: {
    popupMessage: null,
    tostMessage: [],
    user: null,
    loading: true,
    expiredLogin: null,
    showLogin: false,
    historyColor: colors,
    language: lang,
    selectTemplate: -1,
    lastURL_BeforeLogin: false,
  },
  getters: {
    language: (state) => {
      return state.language;
    },
    historyColor: (state) => {
      return state.historyColor;
    },
    expiredLogin: (state) => {
      return state.expiredLogin;
    },
    showLogin: (state) => {
      return state.showLogin;
    },
    selectTemplate: (state) => {
      return state.selectTemplate;
    },
    lastURL_BeforeLogin: (state) => {
      return state.lastURL_BeforeLogin;
    },

    user: (state) => {
      return state.user;
    },
    loading: (state) => {
      return state.loading;
    },

    tostMessage: (state) => {
      return state.tostMessage;
    },
    popupMessage: (state) => {
      return state.popupMessage;
    },
  },
  actions: {
    language(context, language) {
      context.commit("language", language);
    },
    historyColor(context, historyColor) {
      context.commit("historyColor", historyColor);
    },
    expiredLogin(context, expiredLogin) {
      context.commit("expiredLogin", expiredLogin);
    },
    showLogin(context, showLogin) {
      context.commit("showLogin", showLogin);
    },
    selectTemplate(context, selectTemplate) {
      context.commit("selectTemplate", selectTemplate);
    },

    lastURL_BeforeLogin(context, lastURL_BeforeLogin) {
      context.commit("lastURL_BeforeLogin", lastURL_BeforeLogin);
    },

    user(context, user) {
      context.commit("user", user);
    },
    loading(context, loading) {
      context.commit("loading", loading);
    },

    tostMessage(context, tostMessage) {
      context.commit("tostMessage", tostMessage);
    },
    popupMessage(context, popupMessage) {
      context.commit("popupMessage", popupMessage);
    },
  },
  mutations: {
    language(state, language) {
      document.documentElement.lang = language;
      state.language = language;
    },
    historyColor(state, color) {
      let historyColor = cookies.get("historyColor").split(",");
      historyColor.unshift(color);

      if (historyColor.length > 24) historyColor.length = 24;
      state.historyColor = historyColor;
      cookies.set("historyColor", historyColor);
    },
    expiredLogin(state, expiredLogin) {
      state.expiredLogin = expiredLogin;
    },
    showLogin(state, showLogin) {
      state.showLogin = showLogin;
    },
    selectTemplate(state, selectTemplate) {
      state.selectTemplate = selectTemplate;
    },
    lastURL_BeforeLogin(state, lastURL_BeforeLogin) {
      state.lastURL_BeforeLogin = lastURL_BeforeLogin;
    },

    user(state, user) {
      state.user = user;
    },
    loading(state, loading) {
      state.loading = loading;
    },
    tostMessage(state, tostMessage) {
      state.tostMessage.push(tostMessage);
    },

    popupMessage(state, popupMessage) {
      state.popupMessage = popupMessage;
    },
  },
});
