import { createRouter, createWebHistory } from "vue-router";
import actions from "@/plugins/actions";
import api from "@/services/api";
history.pushState(null, null, location.href);
export const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      path: "/Politica_de_Privacidade",
      name: "privacyPolicyPT",
      component: () => import("@/pages/Policy"),
    },
    {
      path: "/Termos_de_Servico",
      name: "termsOfServicePT",
      component: () => import("@/pages/Terms"),
    },
    {
      path: "/Privacy_Policy",
      name: "privacyPolicy",
      component: () => import("@/pages/Policy"),
    },
    {
      path: "/Terms_of_Service",
      name: "termsOfService",
      component: () => import("@/pages/Terms"),
    },
    {
      path: "/linkedin/callback",
      name: "callbackLinkedin",
      component: () => import("@/pages/Home"),
    },

    {
      path: "/contact",
      name: "Contact",
      component: () => import("@/pages/Contact"),
    },

    {
      path: "/",
      name: "Home",
      component: () => import("@/pages/Home"),
    },
    {
      path: "/home",
      name: "Home2",
      component: () => import("@/pages/Home"),
    },
    {
      path: "/reset_password/:token/:email",
      name: "reset",
      component: () => import("@/pages/Reset"),
    },

    {
      path: "/curriculum",
      name: "Curriculum",
      component: () => import("@/pages/Curriculum/Curriculum.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/curriculum/view/:id",
      name: "CurriculumView",
      component: () => import("@/pages/Curriculum/Curriculum.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/curriculum/personalize/:personalize",
      name: "CurriculumPersonalize",
      component: () => import("@/pages/Curriculum/Curriculum.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/t/:personalize",
      name: "CurriculumPersonalize",
      component: () => import("@/pages/Curriculum/Curriculum.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/:token",
      name: "Profile",
      component: () => import("@/pages/Profile"),
    },

    {
      path: "/:catchAll(.*)",

      component: () => import("@/pages/PageNotFound"),
    },
    {
      path: "/not-found",
      name: "not-found",
      component: () => import("@/pages/PageNotFound"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const element = document.getElementsByClassName("modal fade show");
  const arrLength = element.length;
  if (arrLength > 0) {
    const z = arrLength - 1;
    document.getElementsByClassName("modal fade show")[z].click();
    window.history.forward(1);
    //history.pushState(window.Math.random(),window.Math.random(),window.location.href+'#'+ );
    //history.pushState({page: 2}, "opem modal");

    /*
element[z].setAttribute("aria-hidden", "true") 
//element[z].style.display = "none";
element[z].classList.remove("show");
document.getElementsByClassName("modal-backdrop")[z].remove();
  */

    next(false);
    return;
  }
  //Close all modal
  document
    .getElementsByClassName("modal-backdrop")
    .forEach(function callback(value, index) {
      document.getElementsByClassName("modal-backdrop")[index].remove();
    });
  //Close all modal
  actions.dispatch("loading", true);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (actions.getters["user"]) next();
    else if (localStorage.getItem("token") !== null) {
      await api
        .get("/me")
        .then((response) => {
          if (!response.data.error) {
            actions.dispatch("user", response.data.data.user);
          } else {
            localStorage.removeItem("token");
            actions.dispatch("user", null);
            if (response.data.messages) console.log(response.data.messages);
            else console.log("Internal Server Error");
          }
        })
        .catch((error) => {
          console.log("Internal Server Error " + error.message);
        });
    }

    if (actions.getters["user"]) next();
    else {
      actions.dispatch("lastURL_BeforeLogin", to.fullPath);

      next("/");

      setTimeout(() => {
        actions.dispatch("showLogin", true);
      }, 1500);
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  actions.dispatch("loading", false);
});
