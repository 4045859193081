<template>
  <ul class="nav">
    <li v-if="user" class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle d-block d-md-none"
        href="#"
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="@/assets/img/user.svg" class="user-ico" alt="eu" />
      </a>
      <a
        class="nav-link dropdown-toggle d-none d-md-block"
        href="#"
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="@/assets/img/user.svg" class="user-ico" alt="eu" />
        {{ userName }}
      </a>

      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdownMenuLink"
      >
        <li>
          <a
            class="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#mdPasswordChange"
          >
            {{ $t("labels.changePassword") }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="logOut" class="dropdown-item">{{
            $t("labels.logout")
          }}</a>
        </li>
      </ul>
    </li>

    <li v-else class="nav-item">
      <a
        href="#"
        class="nav-link login"
        data-bs-toggle="modal"
        data-bs-target="#mdLogin"
      >
        <span class="d-none d-md-flex ">
          <img src="@/assets/img/user.svg" class="user-ico" alt="eu" />
          {{ $t("labels.loginRegister") }}</span
        >
        <span class=" d-flex d-md-none ">
          <img src="@/assets/img/user.svg" class="user-ico" alt="eu" /> </span
      ></a>
    </li>

    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          :src="require('@/assets/img/flag_' + this.$i18n.locale + '.svg')"
          class="flag-ico"
          :alt="'flag ' + this.$i18n.locale"
        />
      </a>
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdownMenuLink"
      >
        <li v-for="language in listLanguages" :key="language.key">
          <a
            class="dropdown-item"
            href="#"
            @click="changeLanguage(language.key)"
            :class="{ active: this.$i18n.locale === language.key }"
          >
            <img
              :src="require('@/assets/img/flag_' + language.key + '.svg')"
              class="flag-ico"
              :alt="'flag ' + language.key"
            />
            {{ $t(language.label) }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import Vuex from "vuex";
import api from "@/services/api";
import { listLanguagesArr } from "@/plugins/helpers";
export default {
  name: "navRight",

  data() {
    return {
      listLanguages: listLanguagesArr(),
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    logOut() {
      api({
        method: "get",
        url: "me/logout",
        data: {},
      });
      localStorage.removeItem("token");
      this.$router.push("/");
      this.$store.dispatch("user", null);
    },
    changeLanguage(langCode) {
      this.$emit("changeLanguage", langCode);
    },
  },
  computed: {
    ...Vuex.mapGetters(["user"]),
    userName() {
      let fullName = this.user.full_name
        .trim()
        .replace(/\s+/, " ")
        .split(" ");
      return fullName[0].replace(/\b\w/g, (l) => l.toUpperCase());
      //let lastName =fullName.length > 1 ? " " + fullName[fullName.length - 1] : "";
      //return (fullName[0] + lastName).replace(/\b\w/g, (l) => l.toUpperCase());
    },
  },
};
</script>
<style scoped>
.user-ico {
  height: 25px;
  cursor: pointer;
}
.flag-ico {
  height: 20px;
  cursor: pointer;
}

.nav-item .router-link-active {
  font-weight: bold;
}
.nav-item a {
  color: var(--cs) !important;
}
.dropdown-menu a {
  color: var(--cp) !important;
}
.dropdown-menu a {
  color: var(--cp) !important;
}
.dropdown-menu a:hover {
  opacity: 0.8;
  transform: translateY(1px);
  background-color: white;
}
.dropdown-menu a:active {
  opacity: 1 !important;
  transform: translateY(1px);
  background-color: white;
}
.dropdown-item.active {
  font-weight: bold;
  background-color: white;
}

@media (max-width: 768px) {
  .nav-link {
    padding: 0.5rem 0.5rem;
  }
}
</style>
