<template>
  <footer class="section-p">
    <div class="container-fluid">
      <div class="row bg-linear-gradient pt-5 pb-5 d-flex align-items-center">
        <div class="col-12 col-md-6 d-flex justify-content-center mt-2 mb-2">
          <button
            data-aos-duration="1000"
            data-aos="fade-right"
            @click="mdFeedBack.show()"
            class="btn bg-p btn-lg rounded-pill"
            type="button"
          >
            <img
              class="ms-2 img_invert"
              src="../assets/img/message-circle.svg"
              alt="send"
            />
            {{ $t("labels.give_us_your_feedback") }}
          </button>
        </div>

        <div class="col-12 col-md-6 row mt-2 mb-2">
          <div class="col-12 text-center">
            <h3 data-aos-duration="1000" data-aos="fade-left">
              {{ $t("labels.connect_with_us") }}
            </h3>
          </div>

          <div class="d-flex justify-content-center">
            <a
              data-aos="fade-left"
              data-aos-delay="150"
              href="https://www.linkedin.com/company/themycv"
              target="_blankk"
              class="links"
            >
              <img src="../assets/img/social/linkedin.svg"
            /></a>
            <a
              data-aos="fade-left"
              data-aos-delay="300"
              href="https://www.facebook.com/themycv"
              target="_blankk"
              class="links"
            >
              <img src="../assets/img/social/facebook.svg"
            /></a>
            <a
              data-aos="fade-left"
              data-aos-delay="450"
              href="https://www.instagram.com/themycv"
              target="_blankk"
              class="links"
            >
              <img src="../assets/img/social/instagram.svg"
            /></a>
            <a
              data-aos="fade-left"
              data-aos-delay="600"
              href="https://www.youtube.com/channel/UCwpgn-1nF9rI55QtFHebwWQ"
              target="_blankk"
              class="links"
            >
              <img src="../assets/img/social/youtube.svg"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center pt-4 pb-1" data-aos="flip-up">
      <p class="small ps-2 pe-2 mb-0">
        <small class="">
          <router-link
            v-if="$i18n.locale == 'en'"
            target="_blankk"
            to="/Terms_of_Service"
            class="cs links fw-bold"
            >{{ $t("labels.terms_of_service") }}
          </router-link>
          <router-link
            v-else
            target="_blankk"
            to="/Termos_de_Servico"
            class="cs links fw-bold"
            >{{ $t("labels.terms_of_service") }}
          </router-link>
          <span class="cs"> | </span>
          <router-link
            v-if="$i18n.locale == 'en'"
            target="_blankk"
            to="/Privacy_Policy"
            class="cs links fw-bold"
            >{{ $t("labels.privacyPolicy") }}</router-link
          >
          <router-link
            v-else
            target="_blankk"
            to="/Politica_de_Privacidade"
            class="cs links fw-bold"
            >{{ $t("labels.privacyPolicy") }}</router-link
          >
        </small>
      </p>

      <p class="small ps-2 pe-2 cs mt-0">
        <small class="cs">
          {{ $t("homeText.site_protected_reCaptha") }}
          <a
            href="https://policies.google.com/privacy"
            class="cs links fw-bold"
            >{{ $t("labels.privacyPolicy") }}</a
          >
          {{ $t("homeText.site_protected_reCaptha2") }}
          <a
            href="https://policies.google.com/terms"
            class="cs links fw-bold"
            >{{ $t("labels.terms_of_service") }}</a
          >
          {{ $t("homeText.site_protected_reCaptha3") }}
        </small>
      </p>
      <p class="small ps-2 pe-2">
        © 2022, beta version
        <!-- <img src="../assets/img/flag_cv.svg" class="user-ico" alt="eu" />-->
        | {{ $t("labels.allRightReserved") }}
      </p>
    </div>

    <div
      class="modal fade"
      id="mdFeedBack"
      tabindex="-1"
      aria-labelledby="mdFeedBackLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-s border-0">
          <div class="">
            <form autocomplete="off" class="p-3 p-md-4">
              <h3 class="text-center mb-3">
                {{ $t("labels.give_us_your_feedback") }}
              </h3>
              <Alert :alerts="alert"></Alert>
              <div class="d-flex justify-content-center mb-3 mt-1">
                <star-rating
                :class="{ invalid: evaluation_score_invalid }"
                
                  class="ms-2 card_evaluation_score"
                  v-model:rating="score"
                  :increment="1"
                  :show-rating="false"
                >
                </star-rating>
              </div>
              <div class="form-floating">
                <textarea
                  v-model="comment"
                  class="form-control"
                  placeholder="Leave a comment here"
                  style="height: 150px"
                ></textarea>
                <label for="floatingTextarea2">{{
                  $t("labels.leave_your_comment")
                }}</label>
              </div>
              <div id="emailHelp" class="form-text">
                {{ $t("homeText.your_feedback_is_valuable_to_us") }}
              </div>

              <div class="d-grid gap-2 mt-4 mb-2">
                <button
                  :disabled="loading"
                  @click="recaptcha"
                  class="btn btn-primary"
                  type="button"
                >
                  <img
                    class="me-2"
                    src="../assets/img/send-white.svg"
                    alt="send"
                  />
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ $t("labels.send") }}
                </button>
              </div>
            </form>
            <!-- 
            <div class="text-center p-2 ps-3 pe-3 ps-md-4 pe-md-4 ">
              <small class="">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                and
                <a href="https://policies.google.com/terms">Terms of Service</a>
                apply.
              </small>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import StarRating from "@/components/star/star-rating";
import { Modal } from "bootstrap";
import Alert from "@/components/alert/Alert";
import api from "@/services/api";
import Vuex from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      evaluation_score_invalid:false,
      score: 0,
      comment: "",
      loading: false,
      alert: {
        msg: "",
        type: "",
      },
    };
  },
  components: {
    StarRating,
    Alert,
  },

  mounted() {
    this.mdFeedBack = new Modal(document.getElementById("mdFeedBack"));
  },
  computed: {
    ...Vuex.mapGetters(["user"]),
  },
  methods: {
    send(token) {
      if (this.score  < 1) {
        this.evaluation_score_invalid=true;
        return;
      }
      this.evaluation_score_invalid=false;
      this.loading = true;
      api({
        method: "post",
        url: "/feedback",
        data: {
          token: token,
          score: this.score,
          comment: this.comment,
          email: this.user?.email ?? null,
        },
      })
        .then((response) => {
          if (!response.data.error) {
            this.alert.msg = response.data.messages;
            this.alert.type = "success";
            this.loading = false;


           

            setTimeout(() => {
              this.mdFeedBack.hide();
              this.comment = "";
            }, 3000);
          this.score=0;
           this.comment="";

          } else throw new Error(response.data.messages);
        })
        .catch((error) => {
          console.log(error);
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("feedback");
      this.send(token);
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 402px;
}
.links {
  text-decoration: none;
}
.links:hover {
  opacity: 0.6;
  color: var(--cs);
}
.user-ico {
  height: 15px;
}
.bg-linear-gradient {
  background: linear-gradient(
      rgba(17, 70, 166, 0.92),
      rgba(17, 70, 166, 0.84),
      rgba(17, 70, 166, 0.74)
    ),
    url("../assets/img/social/WorldMap.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.card_evaluation_score.invalid{ 
  border: 1px solid #dc3545!important;
    border-radius: 7px;
    padding: 4px;
}
</style>
