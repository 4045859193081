<template>
  <div class="Preloader d-flex align-items-center justify-content-center">
    <img src="../assets/img/loading.gif" alt="loading.gif" />
  </div>
</template>
<script>
export default {
  name: "Preloader",
};
</script>
<style scoped>
.Preloader {
  position: fixed; /* Stay in place */
  z-index:1060 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.85); /* Black w/ opacity */
}
img {
  height: 120px;
}
</style>