<template>
  <div id="moDALALER" v-if="popupMessage.msg">
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalAlertPoupUp"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalAlertPoupUpLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div
          :class="{ loading: popupMessage.type == 'loading' }"
          class="modal-content borderr   border-0 border-top"
        >
          <div class="modal-body">
            <div
              v-if="popupMessage.type != 'loading'"
              class="d-flex justify-content-end "
            >
              <button
                @click="modalClosed"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="mt-2 mb-2 d-flex justify-content-center">
              <div
              style="width: 3rem; height: 3rem;"
                v-if="popupMessage.type == 'loading'"
                class="spinner-border cp mb-3"
                role="status"
              >
                <span class="sr-only"></span>
              </div>

              <svg
                v-else
                class="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Danger:"
              >
                <use :xlink:href="typeIco" />
              </svg>
            </div>
            <div class="ps-2 ps-sm-4 pe-2 pe-sm-4 ">
              <div class="col-12 text-black m-0 fw-bold text-center">
                <h4>{{ popupMessage.title }}</h4>
              </div>
              <div class="col-12 fw-lighter text-center">
                {{ popupMessage.msg }}
              </div>
            </div>
            <div
              class="mt-4 mb-2  d-flex justify-content-center"
              v-if="popupMessage.type != 'loading'"
            >
              <button
                @click="modalClosed"
                type="button"
                class="btn  btn-sm"
                data-bs-dismiss="modal"
              >
                {{ $t("buttons.cancel") }}
              </button>
              <button
                @click="confirmClicked"
                type="button"
                class="btn btn-1  btn-sm"
              >
                {{ $t("buttons.delete") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import actions from "@/plugins/actions";
import Vuex from "vuex";
export default {
  name: "ModalAlert",

  data() {
    return {
      typeIco: "#icowarning",
      modal: null,
    };
  },
  computed: {
    ...Vuex.mapGetters(["popupMessage"]),
  },
 
  watch: {
    popupMessage: {
      immediate: true,
      deep: true,
      handler: function(newValue) {
        if (! newValue?.msg)this.modal.hide();
         
        /*
         setTimeout(() => {
            //this.confirmClicked();
          }, 3000);
          */
      },
    },
  },

  
  mounted() {
    this.modal = new Modal(document.getElementById("modalAlertPoupUp"));
    this.root = document.getElementById("moDALALER");
    let color = "#fe7c72";
    /*
    if (this.alerts.type == "success") color = "#20a779";
    else if (this.alerts.type == "info") color = "#017ac3";
   */

    this.root.style.setProperty("--colorActiveModal", color);
    this.modal.show();

    if (this.popupMessage.type != "loading") {
      setTimeout(() => {
        this.modal.hide();
        actions.dispatch("popupMessage", null);
      }, 60000);
    }
  },
  methods: {
    modalClosed() {
      actions.dispatch("popupMessage", null);
    },
    confirmClicked() {
      this.modal.hide();

      let result = Object.assign({}, this.popupMessage) ?? null;
      result.res = true;
      actions.dispatch("popupMessage", result);
    },
  },
};
</script>
<style scoped>
:root {
  --colorActiveModal: #efa92e;
}

.modal.fade {
  z-index: 1056;
}
.modal.fade {
  background: var(--cp_op_1);
}
.borderr {
  border-top-width: 10px !important;
  border-color: var(--colorActiveModal) !important;
  background-color: whitesmoke !important;
}
.borderr.loading {
  border-color: var(--cp) !important;

}

.borderr.loading .modal-body {
  padding-top: 30px;
  padding-bottom: 44px;
}

.modal-dialog {
  max-width: 450px !important;
}
.btn-1 {
  color: white;
  background-color: var(--colorActiveModal) !important;
}
svg {
  fill: var(--colorActiveModal) !important;
  color: var(--colorActiveModal) !important;
  height: 50px !important;
  width: 50px !important;
}
</style>
