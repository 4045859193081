<template>
  <header class="">
    <div class="container-fluid p-0">
      <nav
        @focusout="handleFocusOut"
        tabindex="0"
        class="navbar navbar-expand-md navbar-light container"
      >
        <div class="container col-md-7 col-lg-8">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <router-link to="/" class="navbar-brand">THE MY CV</router-link>

          <navRight
            class="d-flex d-md-none"
            :selectedLanguage="$i18n.locale"
            @changeLanguage="changeLanguage"
          ></navRight>

          <div
            class="collapse navbar-collapse"
            :class="!visible ? 'collapse' : ''"
            ref="navbarNavDropdown"
            id="navbarNavDropdown"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="" class="nav-link"></router-link>
              </li>
              <li class="nav-item">
                <router-link
                  @click="visible = !visible"
                  to="/"
                  class="nav-link"
                  >{{ $t("labels.home") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="visible = !visible"
                  v-if="user"
                  to="/Curriculum"
                  class="nav-link"
                  >{{ $t("labels.curriculum") }}</router-link
                >
                <a
                  v-else
                  @click="visible = !visible"
                  href="#"
                  class="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#mdLogin"
                >
                  {{ $t("labels.curriculum") }}</a
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="visible = !visible"
                  to="/Contact"
                  class="nav-link"
                  >{{ $t("labels.contact") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div
          class="
            container
            col-md-5 col-lg-4
            d-none d-md-flex
            justify-content-end
            order-first order-md-last
          "
        >
          <navRight @changeLanguage="changeLanguage"></navRight>
        </div>
      </nav>
    </div>
    <PasswordChange />
  </header>
  <div v-if="!aceptCookies">
    <!-- Button trigger modal -->
    <button
      ref="btnPopUp"
      type="button"
      class="btn btn-primary d-none"
      data-bs-toggle="modal"
      data-bs-target="#mdCookiesPopUp"
    ></button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="mdCookiesPopUp"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="mdCookiesPopUpLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content mdCookies">
          <div class="modal-body">
            <img class="cookieImage" src="../assets/img/cookie.svg" alt="" />

            <h5 class="text-center mt-1">{{ $t("messages.AlertCookie3") }}</h5>
            <div class="mt-3 p-2" style="text-align: justify">
              <p>
                {{ $t("messages.AlertCookie1") }}
              </p>
              <p>
                {{ $t("messages.AlertCookie2") }}
                <router-link
                  v-if="$i18n.locale == 'en'"
                  target="_blank"
                  to="/Privacy_Policy"
                  class="links"
                  >{{ $t("labels.privacyPolicy") }}</router-link
                >
                <router-link
                  v-else
                  target="_blank"
                  to="/Politica_de_Privacidade"
                  class="links"
                  >{{ $t("labels.privacyPolicy") }}</router-link
                >
                |
                <router-link
                  v-if="$i18n.locale == 'en'"
                  target="_blankk"
                  to="/Terms_of_Service"
                  class="links"
                  >{{ $t("labels.terms_of_service") }}
                </router-link>

                <router-link
                  v-else
                  target="_blank"
                  to="/Termos_de_Servico"
                  class="links"
                  >{{ $t("labels.terms_of_service") }} </router-link
                >.
              </p>
            </div>

            <div class="p-3 pt-2 d-flex justify-content-end">
              <button
                @click="funAceptCookies"
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {{ $t("labels.accept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordChange from "@/components/PasswordChange";
import navRight from "@/components/subComponrnts/navRight";
import Vuex from "vuex";
import actions from "@/plugins/actions";
import api from "@/services/api.js";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export default {
  name: "Header",
  data() {
    return {
      visible: false,

      windowWidth: window.innerWidth,
      aceptCookies: cookies.get("aceptCookies"),
    };
  },
  mounted() {
    if (!this.aceptCookies)
      setTimeout(() => {
        this.$refs.btnPopUp.click();
      }, 4000);
  },
  components: {
    PasswordChange,
    navRight,
  },

  computed: {
    ...Vuex.mapGetters(["user"]),
  },
  methods: {
    funAceptCookies() {
      cookies.set("aceptCookies", true, "10Y");
      this.aceptCookies = true;
    },
    handleFocusOut() {
      setTimeout(() => {
        this.visible = !this.visible;
      }, 1000);
    },
    collapseMenuHide() {
      this.$refs["navbarNavDropdown"].$el.collapse("hide");
    },
    changeLanguage(langCode) {
      if (this.$i18n.locale !== langCode) {
        this.$gtag.event("click", {
          event_category: "general",
          event_label: "Changhe Language",
          value: langCode,
        });

        this.$i18n.locale = langCode;

        //  if (cookies.get("aceptCookies")) { }

        if(!langCode)langCode='en';
        cookies.set("lang", langCode, "10Y");

        actions.dispatch("language", langCode);

        let baseURL = api.defaults.baseURL;
        baseURL = baseURL.slice(0, baseURL.lastIndexOf("/"));
        //remove /
        api.defaults.baseURL =
          baseURL.slice(0, baseURL.lastIndexOf("/")) + "/" + langCode + "/";
        //remove /
      }
    },
  },
};
</script>

<style scoped>
#mdCookiesPopUp {
  z-index: 1059;
}
.mdCookies {
  background: rgb(204 165 133);
}

.cookieImage {
  width: 100px;
  display: block;
  margin: 18px;
  margin-left: auto;
  margin-right: auto;
}

.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}
nav .container {
  padding-left: 0;
  padding-right: 0;
}

.navbar-toggler {
  padding: 0.25rem 0.25rem;

  border: 0px;
}

header {
  background-color: var(--cp);
  color: var(--cs);
}

.navbar-brand {
  color: var(--cs) !important ;
  font-weight: 900;
  margin-right: 0;
}

.nav-item .router-link-active {
  font-weight: bold;
}
.nav-item a {
  color: var(--cs) !important;
}
.dropdown-menu a {
  color: var(--cp) !important;
}
.dropdown-menu a {
  color: var(--cp) !important;
}
.dropdown-menu a:hover {
  opacity: 0.8;
  transform: translateY(1px);
  background-color: white;
}
.dropdown-menu a:active {
  opacity: 1 !important;
  transform: translateY(1px);
  background-color: white;
}
.dropdown-item.active {
  font-weight: bold;
  background-color: white;
}

@media (min-width: 768px) {
  .navbar-brand {
    margin-right: 1rem;
  }
}
</style>
