import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./routes/router";
import global from "./plugins/global";
import actions from "./plugins/actions";
import GAuth from "vue3-google-oauth2";
import { createI18n } from "vue-i18n";

import en from "./translation/en.json";
import pt_BR from "./translation/pt-BR.json";
import pt_PT from "./translation/pt-PT.json";

import Color from "@/components/color/Color";

import ScriptX from "vue-scriptx";
import Ads from "vue-google-adsense";

import store from "@/plugins/actions";
import { createHead } from "@vueuse/head";

import VueGtag from "vue-gtag";
import { VueReCaptcha } from "vue-recaptcha-v3";

const gAuthOptions = {
  clientId:
    "779710678314-81blcs3eq0iuo2eifhda3b3nh741o973.apps.googleusercontent.com",
  scope: "email",
  prompt: "consent",
  fetch_basic_profile: true,
};

const langues = ["es", "pt_BR", "pt_pt"];
let defaultLangue=store.getters.language;
if(!defaultLangue){
   //'pt-PT' 'pt_PT'
   defaultLangue= langues.includes(navigator.language.replace("-","_")) ? navigator.language.replace("-","_") : null;
    //'en-US' 'en_US'
    if(!defaultLangue) langues.includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0]:'en';
}


const i18n = createI18n({
  locale: defaultLangue,
  messages: { en, pt_BR, pt_PT },
  fallbackLocale: "en",
});

const head = createHead();

createApp(App)
  .component("Color", Color)
  .use(global)
  .use(GAuth, gAuthOptions)
  .use(router)
  .use(actions)
  .use(i18n)
  .use(ScriptX)
  .use(
    VueGtag,
    {
      config: { id: "G-C37N2B2HH3" },
    },
    router
  )
  .use(Ads.Adsense)
  .use(Ads.InArticleAdsense)
  .use(Ads.InFeedAdsense)
  .use(head)
  .use(VueReCaptcha, { siteKey: "6LfjeV4hAAAAANJ33tNCMQ7REFe2Rt855Zav-u3P" })
  .mount("#app");
