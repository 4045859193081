import axios from 'axios';
import store from '@/plugins/actions'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.params = {}
//axios.defaults.params['lang'] = 'pt-BR';

const api=axios.create ({
   baseURL:process.env.VUE_APP_BASE_URL+store.getters.language+"/" ,
    
    
})
api.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token');

export default api;