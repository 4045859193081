<template>
  <div>
    <transition name="fade">
      <div
        v-if="alerts.msg"
        :class="type"
        class=" divAlert alert d-flex align-items-center"
        role="alert"
      >
        <svg
          class="bi flex-shrink-0 me-2"
          width="24"
          height="24"
          role="img"
          aria-label="Danger:"
        >
          <use :xlink:href="typeIco" />
        </svg>
        <div>
          {{ alerts.msg }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "Alert",
  props: ["alerts"],

  computed: {
    type: function() {
      return "alert-" + this.alerts.type;
    },
    typeIco: function() {
      return "#ico" + this.alerts.type;
    },
  },

  watch: {
    alerts: {
      deep: true,
      handler: function(newVal) {
        if (newVal.msg != "")
          setTimeout(function() {
            newVal.msg = "";
          }, 10000);
      },
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
