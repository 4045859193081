<template>
  <div class="container-fluid p-0" v-if="show">
    <div class="container">
      <div class="alert alert-warning mt-2 mb-2" role="alert">
        {{$t("messages.AlertBrowserFBugs1")}}
       <!--  <button @click="linkClick" type="button" class="btn btn-link p-0">
          {{$t("labels.click_here")}}
        </button>
        {{$t("messages.AlertBrowserFBugs2")}} -->
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AlertBrowserFBugs",
  data() {
    return {
show:false
    }
  },
  props: [],
  created() {
   this.show=this.isFacebookApp() 
  },
  methods: {
 
isFacebookApp() {
  let ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    },
    linkClick() {
      navigator.clipboard.writeText(window.location.href);
      alert("Link copiado, agora abre um browser e cole o link");
    },
  },
};
</script>
<style scoped>
</style>
