<template>
<pick-colors v-if="showAlpha===true"
    :colors="historyColor"
    v-model:value="color"
    @change="updateValue"
    show-alpha
  />
   <pick-colors v-else
    :colors="historyColor"
    v-model:value="color"
    @change="updateValue"
    
  />
</template>
<script>
import PickColors from "vue-pick-colors";
import Vuex from "vuex";
import actions from "@/plugins/actions";

export default {
  computed: {
    ...Vuex.mapGetters(["historyColor"]),
  },
  methods: {
    updateValue() {
      this.$emit("update:modelValue", this.color);
      this.addHistoric(this.color);
    },

    addHistoric(color) {
      setTimeout(() => {
        if (color == this.color) {
          actions.dispatch("historyColor", color);
        }
      }, 1500);
    },
  },

  data() {
    return {
      color:
        this.modelValue == null || this.modelValue == ""
          ? (this.color = "#27BC57")
          : this.modelValue,
    };
  },
  props: {
    modelValue: {
      default: "#27BC57",
    },
     showAlpha: {
        type: Boolean,
      default: false,
    },
  },

  watch: {
    modelValue: {
      deep: true,
      handler: function (newVal) {
        if (newVal == null || newVal == "") this.color = "#27BC57";
        else this.color = newVal;
      },
    },
  },
  components: {
    PickColors,
  },
  name: "Color",
};
</script>
<style scoped>
</style>
