<script>
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "The my CV",
    },

    description: {
      type: String,
      default:
        "Create your Professional Resume or Curriculum with Modern Design",
    },
    img: {
      type: String,
      default: require("@/assets/img/themycv_banner.png"),
    },
  },

  setup(props) {
    useHead({
      // Can be static or computed
      title: props.title,

      script: [
        {
          async: "true",

          src: `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2891337367467116`,
          crossorigin: "anonymous",
        },
      ],

      meta: [
        {
          name: `description`,
          property: `description`,
          content: props.description,
        },
        {
          name: `og:title`,
          property: `og:title`,
          content: props.title,
        },
        {
          name: `og:description`,
          property: `og:description`,
          content: props.description,
        },

        {
          name: `og:image`,
          property: `og:image`,
          content: props.img,
        },

        {
          name: `twitter:title`,
          property: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          property: `twitter:description`,
          content: props.description,
        },

        {
          name: `twitter:image`,
          property: `twitter:image`,
          content: props.img,
        },
        {
          name: `twitter:card`,
          property: `twitter:card`,
          content: props.img,
        },
      ],
    });
  },
});
</script>
