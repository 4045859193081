<template>
  <div>
    <div
      class="modal fade"
      id="mdForgot"
      tabindex="-1"
      aria-labelledby="mdForgotLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-s p-3 p-md-4">
          <div class="text-center cp mb-4">
            <h5>{{ $t("labels.forgot_your_password") }}</h5>
          </div>

          <Alert :alerts="alert"></Alert>

          <div class="form-floating mb-3">
            <input
              v-model="email"
              :class="{ 'is-invalid': !emailValide }"
              type="email"
              autocomplete="email"
              class="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label for="floatingInput">{{ $t("labels.email_address") }}</label>
          </div>

          <div class="d-grid gap-2 mt-3">
            <button
              :disabled="loading"
              @click="recaptcha('forget')"
              class="btn btn-primary"
              type="button"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ $t("labels.reset_password") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="mdLogin"
      tabindex="-1"
      aria-labelledby="mdLoginLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-s border-0">
          <div class="container">
            <div class="row navs-title">
              <div
                @click="setAbaLogin()"
                :class="abaLogin ? 'cp bg-s' : 'bg-s1 cs '"
                class="col-6 text-center p-2"
              >
                <h5>{{ $t("labels.login") }}</h5>
              </div>
              <div
                @click="setAbaRegister()"
                :class="!abaLogin ? 'cp bg-s' : 'bg-s1 cs '"
                class="col-6 text-center p-2"
              >
                <h5>{{ $t("labels.register") }}</h5>
              </div>
            </div>
          </div>
          <div class="">
            <form v-if="abaLogin" autocomplete="off" class="p-3 p-md-4">
              <div class="row mb-4 d-flex justify-content-center">
                <div class="col-auto">
                  <button
                    @click="loginWithFacebook"
                    class="btn btnIconSocial d-flex justify-content-center"
                    type="button"
                  >
                    <img src="../assets/img/facebook_circled.svg" alt="" />
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    @click="google"
                    class="btn btnIconSocial d-flex justify-content-center"
                    type="button"
                  >
                    <img src="../assets/img/google-plus_circled.svg" alt="" />
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    @click="loginWithLinkedin"
                    class="btn btnIconSocial d-flex justify-content-center"
                    type="button"
                  >
                    <img src="../assets/img/linkedin_circled.svg" alt="" />
                  </button>
                </div>
              </div>

              <Alert v-if="expiredLogin" :alerts="expiredLogin"></Alert>
              <Alert :alerts="alert"></Alert>

              <div class="form-floating mb-3">
                <input
                  v-model="email"
                  :class="{ 'is-invalid': !emailValide }"
                  type="email"
                  autocomplete="email"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("labels.email_address") }}</label>
              </div>
              <div class="form-floating">
                <input
                  v-model="password"
                  autocomplete="off"
                  :class="{ 'is-invalid': !passwordValide }"
                  :type="passwordFieldType"
                  class="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                />
                <label for="floatingPassword">{{
                  $t("labels.password")
                }}</label>
                <div class="form-text text-end showHidePassword">
                  <img
                    @click="showHidePassword"
                    v-if="passwordFieldType == 'password'"
                    class="ico-show-hide"
                    src="../assets/img/eye_show.svg"
                    alt=""
                  />
                  <img
                    @click="showHidePassword"
                    v-else
                    class="ico-show-hide"
                    src="../assets/img/eye_hide.svg"
                    alt=""
                  />
                </div>
              </div>
              <div v-if="!expiredLogin" class="mt-2 d-flex justify-content-end">
                <a href="#" @click="opemForgotPasswordMd" class="cp1 small">{{
                  $t("labels.forgot_your_password")
                }}</a>
              </div>

              <div class="d-grid gap-2 mt-4 mb-2">
                <button
                  :disabled="loading"
                  @click="recaptcha('sigIn')"
                  class="btn btn-primary"
                  type="button"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ $t("labels.signIn") }}
                </button>
              </div>
            </form>

            <form v-else autocomplete="off" class="p-3 p-md-4">
              <div class="row mb-4 d-flex justify-content-center">
                <div class="col-auto">
                  <button
                    @click="loginWithFacebook"
                    class="btn btnIconSocial d-flex justify-content-center"
                    type="button"
                  >
                    <img src="../assets/img/facebook_circled.svg" alt="" />
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    @click="google"
                    class="btn btnIconSocial d-flex justify-content-center"
                    type="button"
                  >
                    <img src="../assets/img/google-plus_circled.svg" alt="" />
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    @click="loginWithLinkedin"
                    class="btn btnIconSocial d-flex justify-content-center"
                    type="button"
                  >
                    <img src="../assets/img/linkedin_circled.svg" alt="" />
                  </button>
                </div>
              </div>
              <Alert :alerts="alert"></Alert>
              <div class="form-floating mb-3">
                <input
                  v-model="full_name"
                  :class="{ 'is-invalid': !full_nameValide }"
                  type="text"
                  autocomplete="name"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("labels.full_name") }}</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  v-model="email"
                  :class="{ 'is-invalid': !emailValide }"
                  type="email"
                  autocomplete="email"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("labels.email_address") }}</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  v-model="password"
                  autocomplete="off"
                  :class="{ 'is-invalid': !passwordValide }"
                  :type="passwordFieldType"
                  class="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                />
                <label>{{ $t("labels.password") }}</label>
                <div class="invalid-feedback">
                  {{ $t("labels.minimum_password_length") }}
                </div>
                <div class="form-text text-end showHidePassword">
                  <img
                    @click="showHidePassword"
                    v-if="passwordFieldType == 'password'"
                    class="ico-show-hide"
                    src="../assets/img/eye_show.svg"
                    alt=""
                  />
                  <img
                    @click="showHidePassword"
                    v-else
                    class="ico-show-hide"
                    src="../assets/img/eye_hide.svg"
                    alt=""
                  />
                </div>
              </div>

              <div class="form-floating mb-3">
                <input
                  v-model="confirmePassword"
                  autocomplete="off"
                  :class="{ 'is-invalid': !confirmePasswordValide }"
                  :type="passwordFieldType"
                  class="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                />
                <label>{{ $t("labels.confirm_password") }}</label>
                <div class="form-text text-end showHidePassword">
                  <img
                    @click="showHidePassword"
                    v-if="passwordFieldType == 'password'"
                    class="ico-show-hide"
                    src="../assets/img/eye_show.svg"
                    alt=""
                  />
                  <img
                    @click="showHidePassword"
                    v-else
                    class="ico-show-hide"
                    src="../assets/img/eye_hide.svg"
                    alt=""
                  />
                </div>
              </div>

              <div class="d-grid gap-2 mt-4 mb-2">
                <button
                  :disabled="loading"
                  @click="recaptcha('sigIn')"
                  class="btn btn-primary"
                  type="button"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ $t("labels.register") }}
                </button>
              </div>
            </form>
            <div class="text-center p-2 ps-3 pe-3 ps-md-4 pe-md-4">
              <small class="text-muted">
                {{ $t("labels.by_continuing_you_agree") }}

                <router-link
                  v-if="$i18n.locale == 'en'"
                  target="_blank"
                  to="/Terms_of_Service"
                  class=""
                  >{{ $t("labels.terms_of_service") }}
                </router-link>
                <router-link
                  v-else
                  target="_blank"
                  to="/Termos_de_Servico"
                  class=""
                  >{{ $t("labels.terms_of_service") }}
                </router-link>
                {{ $t("labels.and") }}
                <router-link
                  v-if="$i18n.locale == 'en'"
                  target="_blank"
                  to="/Privacy_Policy"
                  class=""
                  >{{ $t("labels.privacyPolicy") }}</router-link
                >
                <router-link
                  v-else
                  target="_blank"
                  to="/Politica_de_Privacidade"
                  class=""
                  >{{ $t("labels.privacyPolicy") }}</router-link
                >
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api.js";
import Alert from "@/components/alert/Alert";
import { Modal } from "bootstrap";
import actions from "@/plugins/actions";
import Vuex from "vuex";
import { getCountry } from "@/plugins/helpers";

export default {
  name: "Login",
  components: {
    Alert,
  },
  created: function() {
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");
    if (code) {
      this.loginWithLinkedinCalback(code);
    }

    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "679876379636055",
        xfbml: true,
        version: "v11.0",
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  mounted() {
    this.modal = new Modal(document.getElementById("mdLogin"));
    this.modalForgot = new Modal(document.getElementById("mdForgot"));
  },

  data: function() {
    return {
      full_name: "",
      email: "",
      password: "",
      confirmePassword: "",
      full_nameValide: true,
      emailValide: true,
      passwordValide: true,
      confirmePasswordValide: true,
      passwordFieldType: "password",

      abaLogin: true,
      loading: false,
      modal: "",
      modalForgot: "",

      alert: {
        msg: "",
        type: "",
      },
    };
  },
  computed: {
    ...Vuex.mapGetters(["expiredLogin"]),
    ...Vuex.mapGetters(["showLogin"]),
  },

  watch: {
    showLogin: {
      deep: true,
      handler: function(newVal) {
        if (newVal) this.modal.show();
      },
    },
    expiredLogin: {
      deep: true,
      handler: function(newVal) {
        if (newVal) this.modal.show();
      },
    },
  },
  methods: {
    welcome() {
      if (actions.getters["lastURL_BeforeLogin"] === false)
        this.$router.push({ name: "Curriculum" });
      else {
        this.$router.push(actions.getters["lastURL_BeforeLogin"]);
        actions.dispatch("lastURL_BeforeLogin", false);
      }
      /* */
    },

    async recaptcha(function_name) {
      let action = function_name;
      if (function_name == "sigIn") {
        action = this.abaLogin ? "login" : "register";
      }

      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha(action);
      this[function_name](token);
    },

    updateUserCountry: function(country) {
      var bodyFormData = new FormData();
      bodyFormData.append("country", country);
      api({
        method: "post",
        url: "me/update/country",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data; " },
      }).catch((error) => {
        console.log(error);
      });
    },
    socialLogin(response, LoginMethod = "") {
      localStorage.setItem("token", response.data.data.token);
      api.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.data.token;
      if (!this.expiredLogin) {
        if (!response.data.data.user.country) {
          let country = getCountry();
          response.data.data.user.dialCode = country;
          response.data.data.user.curriculums[0].dialCode = country;
          // response.data.data.user.country = country;
          // response.data.data.user.nationality = country;
          this.updateUserCountry(country);
        }

        this.$gtag.event("socialLogin", {
          event_category: "social",
          event_label: LoginMethod,
        });
        this.$store.dispatch("user", response.data.data.user);
        this.welcome(response.data.data.user.new);
      } else {
        if (this.expiredLogin.userID != response.data.data.user.id)
          location.reload();
        this.expiredLogin = null;
      }
      this.modal.hide();
      this.email = "";
      this.password = "";
    },
    loginWithFacebook() {
      window.FB.login(
        (response) => {
          if (response.status != "connected") return;

          let token = response.authResponse.accessToken;
          if (!token || token.trim() == "") return;
          actions.dispatch("loading", true);

          let bodyFormData = new FormData();
          bodyFormData.append("token", token);

          api({
            method: "POST",
            url: "/facebook",
            data: bodyFormData,
          })
            .then((response) => {
              if (!response.data.error) {
                this.socialLogin(response, "Facebook");
              } else {
                if (response.data.messages)
                  this.alert.msg = response.data.messages;
                else this.alert.msg = "Internal Server Error";
                this.alert.type = "danger";
              }
              this.loading = false;
            })
            .catch((error) => {
              this.alert.msg = error.message;
              this.alert.type = "danger";
              this.loading = false;
            })
            .finally(() => {
              actions.dispatch("loading", false);
            });
        },
        { scope: "public_profile,email" }
      );
    },
    loginWithLinkedin() {
      let redirectUri = window.location.origin;

      let url = process.env.VUE_APP_BASE_URL + "linkedin";
      url = new URL(url);

      url.searchParams.append("redirectUri", redirectUri);

      location.href = url;
    },
    loginWithLinkedinCalback(code) {
      let redirectUri = window.location.origin;
      let bodyFormData = new FormData();
      bodyFormData.append("code", code);
      bodyFormData.append("redirectUri", redirectUri);

      api({
        method: "post",
        url: "/linkedin/callback",
        data: bodyFormData,
      })
        .then((response) => {
          if (!response.data.error) {
            this.socialLogin(response, "Linkedin");
          } else {
            if (response.data.messages) this.alert.msg = response.data.messages;
            else this.alert.msg = "Internal Server Error";
            this.alert.type = "danger";
          }
          this.loading = false;
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        })
        .finally(() => {
          actions.dispatch("loading", false);
        });
    },

    async google() {
      const authCode = await this.$gAuth.getAuthCode();

      if (!authCode || authCode.trim() == "") return null;
      actions.dispatch("loading", true);
      let redirectUri = window.location.origin;
      //if(redirectUri.slice(-1)=='/')redirectUri=redirectUri.slice(0, -1);
      let bodyFormData = new FormData();
      bodyFormData.append("authCode", authCode);
      bodyFormData.append("redirectUri", redirectUri);

      api({
        method: "post",
        url: "/google",
        data: bodyFormData,
      })
        .then((response) => {
          if (!response.data.error) {
            this.socialLogin(response, "Google");
          } else {
            if (response.data.messages) this.alert.msg = response.data.messages;
            else this.alert.msg = "Internal Server Error";
            this.alert.type = "danger";
          }
          this.loading = false;
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        })
        .finally(() => {
          actions.dispatch("loading", false);
        });
    },

    forget(token) {
      this.emailValide = this.$validEmail(this.email);
      if (!this.emailValide) return;

      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("email", this.email);
      bodyFormData.append("token", token);
      bodyFormData.append("url", window.location.origin + "/reset_password");

      api({
        method: "post",
        url: "/forgot-password",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data; " },
      })
        .then((response) => {
          if (!response.data.error) {
            this.alert.msg = response.data.messages;
            this.alert.type = "success";
            this.loading = false;
            setTimeout(() => this.modalForgot.hide(), 3000);
          } else {
            if (response.data.messages) this.alert.msg = response.data.messages;
            else this.alert.msg = "Internal Server Error";
            this.alert.type = "danger";
          }
          this.loading = false;
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },

    opemForgotPasswordMd() {
      this.modal.hide();
      this.modalForgot.show();
    },
    showHidePassword() {
      this.passwordFieldType =
        this.passwordFieldType == "password" ? "text" : "password";
    },
    setAbaRegister() {
      this.abaLogin = false;
    },
    setAbaLogin() {
      this.abaLogin = true;
    },
    sigIn(token) {
      if (!this.expiredLogin) {
        localStorage.removeItem("token");
        this.$store.dispatch("user", null);
      }
      if (!this.abaLogin) {
        this.full_nameValide = this.$validFull_name(this.full_name);
        if (!this.full_nameValide) return;
      }

      this.emailValide = this.$validEmail(this.email);
      if (!this.emailValide) return;

      this.passwordValide = this.$valid(this.password, 8);
      if (!this.passwordValide) return;

      if (!this.abaLogin) {
        this.confirmePasswordValide =
          this.password === this.confirmePassword ? true : false;
        if (!this.confirmePasswordValide) return;
      }
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("email", this.email);
      bodyFormData.append("password", this.password);
      bodyFormData.append("full_name", this.full_name);
      bodyFormData.append("token", token);

      var url = "/login";
      if (!this.abaLogin) {
        bodyFormData.append("country", getCountry());
        url = "/register";
      }

      api({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data; " },
      })
        .then((response) => {
          if (!response.data.error) {
            localStorage.setItem("token", response.data.data.token);
            api.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.data.token;
            if (!this.expiredLogin) {
              this.$store.dispatch("user", response.data.data.user);

              this.$gtag.event("socialLogin", {
                event_category: "email",
                event_label: "email",
              });
              this.welcome(response.data.data.user.new);
            } else {
              if (this.expiredLogin.userID != response.data.data.user.id)
                location.reload();
              this.expiredLogin = null;
            }
            this.modal.hide();
            this.email = "";
            this.password = "";
          } else {
            if (response.data.messages) this.alert.msg = response.data.messages;
            else this.alert.msg = "Internal Server Error";
            this.alert.type = "danger";
          }
          this.loading = false;
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 402px;
}
.ico-show-hide {
  height: 24px;
  width: 30px;
  margin-top: -3px;
  margin-right: 3px;
  cursor: pointer;
}

.ico-show-hide:hover {
  opacity: 0.6;
}
.ico-show-hide:active {
  opacity: 1 !important;
  transform: translateY(1px);
}

button.btnIconSocial {
  height: 70px;
  width: 70px;
  background: transparent;
}

.btnIconSocial img {
  height: 65px;
  width: 65px;
}

.navs-title div {
  cursor: pointer;
}

.navs-title div.bg-s {
  border-radius: 0.3rem 0.3rem 0 0;
  margin-top: -4px;
}

.navs-title div:hover {
  opacity: 0.9;
}

.navs-title div:active {
  opacity: 1 !important;
}
@media (max-width: 768px) {
  button.btnIconSocial {
    height: 60px;
    width: 60px;
  }

  .btnIconSocial img {
    height: 60px;
    width: 60px;
  }
}
</style>
