import api from '@/services/api'
export default {
  install: (app) => {

    
    app.config.globalProperties.$validEmail = (email, required = true) => {

      if (!required && !email.trim()) {
        return true;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
    app.config.globalProperties.$validFull_name = (str) => {

      if (/\d/.test(str)) return false;

      if (str.trim().length < 6) return false;
     
      return str.trim().indexOf(' ') != -1 ? true : false;
      
    }

    app.config.globalProperties.$validMessage = (str) => {
      
      if (str.trim().length < 5) return false;
      return str.trim().indexOf(' ') != -1 ? true : false

    }

    

    app.config.globalProperties.$valid = (str, min = 0,max = 0) => {
      if (str.length < min) return false;
      if (max !=0 && str.length > max) return false;
      return str.trim() ? true : false
    }

    app.config.globalProperties.$valid1 = async () => {
      const responses = await api.get('/me')

      console.log(responses.data.dados.user)


      return responses.data.dados.user
    }

  }
}