<template>
  <TheHead></TheHead>

  <Header v-if="hideHeaderFother" />

  <TostAlert />
  <AlertBrowserFBugs />
  <main>
    <Preloader v-if="loading"></Preloader>
    <router-view></router-view>
  </main>
  <Login />

  <Footer v-if="hideHeaderFother" />
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/styles.css";
import "./assets/css/fontMontserrat.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Login from "./components/Login";
import api from "./services/api";
import Vuex from "vuex";
import Preloader from "./components/Preloader";
import TheHead from "./components/TheHead";
import AlertBrowserFBugs from "./components/alert/AlertBrowserFBugs";
import TostAlert from "@/components/alert/TostAlert";

export default {
  name: "App",
  components: {
    AlertBrowserFBugs,
    TheHead,
    Footer,
    Header,
    Login,
    Preloader,
    TostAlert,
  },
  async created() {
    if (localStorage.getItem("token") !== null) {
      await api({
        method: "get",
        url: "/me",
      })
        .then((response) => {
          if (!response.data.error) {
            this.$store.dispatch("user", response.data.data.user);
          } else {
            if (!response.data.messages) console.log("Internal Server Error");
          }
        })
        .catch((error) => {
          console.log("Internal Server Error " + error.message);
        });
    }
  },
  watch: {
    '$route.name': function() {
     
      setTimeout(() => {
      AOS.init();
            }, 1000);
 
          
    },
  },
  computed: {
    ...Vuex.mapGetters(["user"]),
    ...Vuex.mapGetters(["loading"]),
    hideHeaderFother() {
     
      if (this.$route.name == "Profile") return false;
    
      return true;
    },
  },
  methods: {
    ...Vuex.mapActions(["getUser"]),
  },
  mounted() {
   
    setTimeout(() => {
      AOS.init();
            }, 1000);
  },
};
</script>
