<template>
  <div>
    <div
      :id="idd"
      :class="{ toast_lg: alerts.msg.length > 60 }"
      class="toast align-items-center   border-0 border-start  mb-2"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="">
        <div class="toast-body">
          <div class="row align-items-center">
            <div class="col-auto pe-2">
              <svg
                class="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Danger:"
              >
                <use :xlink:href="typeIco" />
              </svg>
            </div>
            <div class="col p-0">
              <div class="row">
                <div class="col-12 text-black m-0 fw-bold">
                  {{ $t("labels." + alerts.type) }}
                </div>

                <div class="col-12 fw-lighter ">{{ alerts.msg }}</div>
              </div>
            </div>
            <div class="col-auto ps-2">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "bootstrap";
export default {
  name: "TostAlert",
  props: ["alerts"],

  data() {
    return {
      toast: null,
      idd: "ss" + Math.random() * 100,
    };
  },
  computed: {
    type: function() {
      return "alert-" + this.alerts.type;
    },
    typeIco: function() {
      return "#ico" + this.alerts.type;
    },
  },

  mounted() {
    this.tostShow();
    this.root = document.getElementById(this.idd);
    let color = "#efa92e";
    if (this.alerts.type == "success") color = "#20a779";
    else if (this.alerts.type == "info") color = "#017ac3";

    this.root.style.setProperty("--colorActive", color);
  },
  methods: {
    tostShow() {
      this.toast = new Toast(document.getElementById(this.idd));
      this.toast.show();
    },
  },
};
</script>
<style scoped>
:root {
  --colorActive: #efa92e;
}

.toast {
  border-left-width: 7px !important;
  border-color: var(--colorActive) !important;
  background-color: whitesmoke !important;
  margin-left: auto !important;
}

svg {
  fill: var(--colorActive);
  color: var(--colorActive);
}

@media (min-width: 768px) {
  .toast {
    width: 400px !important;
  }
  .toast_lg {
    width: 500px !important;
  }
}
</style>
